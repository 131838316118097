
export const github = "https://github.com/lawvs/profile"
export const sha = "b293b6e48d5054f9aad54207b2be736624bd5f95"
export const abbreviatedSha = "b293b6e48d"
export const branch = "master"
export const tag = undefined
export const tags = []
export const lastTag = undefined
export const author = "dependabot[bot]"
export const authorEmail = "49699333+dependabot[bot]@users.noreply.github.com"
export const authorDate = "2025-02-20T17:08:26+08:00"
export const committer = "GitHub"
export const committerEmail = "noreply@github.com"
export const committerDate = "Thu Feb 20 17:08:26 2025 +0800"
export const commitMessage = "chore(deps-dev): bump serialize-javascript from 6.0.1 to 6.0.2 (#53)"